export const FirebaseConfig = {
	projectId: "app-console-345017",
	appId: "1:202370350174:web:af1ba4a4a6ae640f4bc8cf",
	databaseURL: "https://app-console-345017-default-rtdb.firebaseio.com",
	storageBucket: "app-console-345017.appspot.com",
	locationId: "us-central",
	apiKey: "AIzaSyD-BgIuf1JWBBnp0B50CtWi0hC9QNkLFj8",
	authDomain: "app-console-345017.firebaseapp.com",
	messagingSenderId: "202370350174",
	measurementId: "G-DDQEYDGQL6"
};
