import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { FirebaseProvider, store } from "common";
import { FirebaseConfig } from "./config/FirebaseConfig";
import { GoogleMapApiConfig } from "./config/GoogleMapApiConfig";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { ThemeProvider } from "@mui/styles";
import { createTheme } from "@mui/material";
import { useJsApiLoader } from "@react-google-maps/api";
import CircularLoading from "./components/CircularLoading";

const HomePage = React.lazy(() => import("./views/HomePage"));
const LoginPage = React.lazy(() => import("./views/LoginPage"));
const PrivacyPolicy = React.lazy(() => import("./views/PrivacyPolicy"));
const AboutUs = React.lazy(() => import("./views/AboutUs"));
const AuthLoading = React.lazy(() => import("./views/AuthLoading"));
const ProtectedRoute = React.lazy(() => import("./views/ProtectedRoute"));
const MyProfile = React.lazy(() => import("./views/MyProfile"));
const BookingHistory = React.lazy(() => import("./views/BookingHistory"));
const Dashboard = React.lazy(() => import("./views/Dashboard"));
const CarTypes = React.lazy(() => import("./views/CarTypes"));
const AddBookings = React.lazy(() => import("./views/AddBookings"));
const Promos = React.lazy(() => import("./views/Promos"));
const Users = React.lazy(() => import("./views/Users"));
const Notifications = React.lazy(() => import("./views/Notifications"));
const Settings = React.lazy(() => import("./views/Settings"));
const CancellationReasons = React.lazy(() => import("./views/CancellationReasons"));
const AddMoney = React.lazy(() => import("./views/AddMoney"));
const Withdraws = React.lazy(() => import("./views/Withdraws"));
const AllReports = React.lazy(() => import("./views/AllReports"));
const ContactUs = React.lazy(() => import("./views/ContactUs"));
const UserWallet = React.lazy(() => import("./views/UserWallet"));
const CarsList = React.lazy(() => import("./views/CarsList"));
const TermsConditions = React.lazy(() => import("./views/TermsConditions"));
const ServicesPage = React.lazy(() => import("./views/ServicesPage"));
const FaqsPage = React.lazy(() => import("./views/FaqsPage"));

const libraries = ["geometry", "drawing", "places"];

i18n.use(initReactI18next).init({
	resources: {},
	fallbackLng: "en",
	ns: ["translations"],
	defaultNS: "translations",
	interpolation: {
		escapeValue: false
	}
});

function App() {
	useJsApiLoader({
		id: "google-map",
		googleMapsApiKey: GoogleMapApiConfig,
		libraries
	});

	const theme = createTheme();

	return (
		<Provider store={store}>
			<FirebaseProvider config={FirebaseConfig}>
				<ThemeProvider theme={theme}>
					<AuthLoading>
						<BrowserRouter>
							<Suspense fallback={<CircularLoading />}>
								<Routes>
									<Route
										path="/dashboard"
										element={
											<ProtectedRoute permit={"admin,fleetadmin"}>
												<Dashboard />
											</ProtectedRoute>
										}
									/>
									<Route
										path="/bookings"
										element={
											<ProtectedRoute
												permit={"customer,admin,driver,fleetadmin"}
											>
												<BookingHistory />
											</ProtectedRoute>
										}
									/>
									<Route
										path="/profile"
										element={
											<ProtectedRoute
												permit={"customer,admin,driver,fleetadmin"}
											>
												<MyProfile />
											</ProtectedRoute>
										}
									/>
									<Route
										path="/cartypes"
										element={
											<ProtectedRoute permit={"admin"}>
												<CarTypes />
											</ProtectedRoute>
										}
									/>
									<Route
										path="/cars"
										element={
											<ProtectedRoute permit={"admin,fleetadmin,driver"}>
												<CarsList />
											</ProtectedRoute>
										}
									/>
									<Route
										path="/cancelreasons"
										element={
											<ProtectedRoute permit={"admin"}>
												<CancellationReasons />
											</ProtectedRoute>
										}
									/>
									<Route
										path="/addbookings"
										element={
											<ProtectedRoute permit={"admin,fleetadmin,customer"}>
												<AddBookings />
											</ProtectedRoute>
										}
									/>
									<Route
										path="/promos"
										element={
											<ProtectedRoute permit={"admin"}>
												<Promos />
											</ProtectedRoute>
										}
									/>
									<Route
										path="/users"
										element={
											<ProtectedRoute permit={"admin,fleetadmin"}>
												<Users />
											</ProtectedRoute>
										}
									/>
									<Route
										path="/notifications"
										element={
											<ProtectedRoute permit={"admin"}>
												<Notifications />
											</ProtectedRoute>
										}
									/>
									<Route
										path="/addtowallet"
										element={
											<ProtectedRoute permit={"admin"}>
												<AddMoney />
											</ProtectedRoute>
										}
									/>
									<Route
										path="/userwallet"
										element={
											<ProtectedRoute permit={"customer,driver"}>
												<UserWallet />
											</ProtectedRoute>
										}
									/>
									<Route
										path="/withdraws"
										element={
											<ProtectedRoute permit={"admin"}>
												<Withdraws />
											</ProtectedRoute>
										}
									/>
									<Route
										path="/allreports"
										element={
											<ProtectedRoute permit={"admin,fleetadmin"}>
												<AllReports />
											</ProtectedRoute>
										}
									/>
									<Route
										path="/settings"
										element={
											<ProtectedRoute permit={"admin"}>
												<Settings />
											</ProtectedRoute>
										}
									/>
									<Route path="/about-us" element={<AboutUs />} />
									<Route path="/contact-us" element={<ContactUs />} />
									<Route
										path="/terms-and-conditions"
										element={<TermsConditions />}
									/>
									<Route path="/privacy-policy" element={<PrivacyPolicy />} />
									<Route path="/login" element={<LoginPage />} />
									<Route path="/services" element={<ServicesPage />} />
									<Route path="/faqs" element={<FaqsPage />} />
									<Route path="/" element={<HomePage />} />
								</Routes>
							</Suspense>
						</BrowserRouter>
					</AuthLoading>
				</ThemeProvider>
			</FirebaseProvider>
		</Provider>
	);
}

export default App;
